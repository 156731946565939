import React from 'react';
import { navigate } from 'gatsby';

import Layout from '../components/layout';

import '../styles/hymots.css';

const Hymots = (props) => {

    const handleClick = (id, url) => {
        if (url) {
            navigate(url);
        }

        const checkExist = setInterval(function () {
            if (document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: 'smooth'
                });

                clearInterval(checkExist);
            }
        }, 100);
    }

    return (
        <Layout location={props.location}>
            <div className='hymots'>
                <div className='hymots__top'>
                    <div className='hymots__top__content'>
                        <h1 className='hymots__top__content__title'>hymots<sup><small>®</small></sup></h1>
                        <p className='hymots__top__content__detail'>
                            Micro Agent-Based Modeling and Simulations platform enabling digital twins, serious games, experimentation, and training solutions.
                            The platform is designed for building Modelling and Simulation as a Service (MSaaS) and micro agent-based solutions that achieve high 
                            scalability, performance and fidelity. The computations are highly parallelized enabled by hardware accelerated (GPU) processing
                             techniques.The platform was originally developed to build an operational and tactics simulator for all domains including land, air,
                              sea and cyber environments. The architecture is component based and highly configurable and today the platform is equipped to fit 
                              into a broad range of solutions and with ready modules. <br/>
                              <br/>
                            An immersive user-experience based on a high definition 3D graphics is enabled by using the latest game engine rendering capabilities.
                             The platform is enabled with a diverse set of ready and configurable components which can be orchestrated using a Drag and Drop graphical 
                             user interface. Examples of components are terrains, buildings, vehicles, weather, logistics, human and social behavior. Solutions can 
                             be deployed and accessed remotely in an MSaaS configuration supported by high performance simulations running in our datacenter. 
                             Our on-premise deployment options include local offline and even mobile installations.<br/>
                             <br/>
                             <u onClick={() => handleClick('modeling-and-simulation', '/services')}>Learn more</u> about our modeling and simulation services such as training, model development and orchestration of domain specific 
                            simulation or <u onClick={() => navigate('/contact')}>get in touch</u> to discuss your needs and any special requirements.
                        </p>
                    </div>
                    <img src='/images/hymots-2.png' className='hymots__top__image' alt='hymots' />
                </div>
            </div>
        </Layout>
    );
}

export default Hymots;